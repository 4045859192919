/**
 * These types are defined in a standalone file so that they can be imported
 * into the `scripts/migrate-envjs` tool without including other modules.
 */
export type FlagInfo = {
  description: string;
};

export type ConfigFlagInfo = FlagInfo & {
  type: string;
  example: string;
};

export enum Application {
  CollaBoardWeb = "CollaBoardWeb",
  CollaBoardBackOffice = "CollaBoardBackOffice",
}

export type Client = {
  application: Application;
  host: string;
};
