import { BackofficeFlag } from "./CollaBoardBackOffice/flags";
import type { BackofficeRuntimeConfig } from "./CollaBoardBackOffice/runtimeConfig.types";
import type { RuntimeConfig as WebRuntimeConfig } from "./CollaBoardWeb/runtimeConfig.types";
import { Application } from "./types";

/**
 * @NOTE - Uses a function rather than exporting the value directly so that the
 * value be can mocked easily.
 */
export const getRuntimeConfig = (): BackofficeRuntimeConfig => {
  /**
   * @TODO #7992 - Once migrated we can revert this to window.runtimeConfig
   */
  return window.dynamicRuntimeConfig;
};

export const isFeatureActive = (flag: BackofficeFlag): boolean => {
  return !!getRuntimeConfig().features?.[flag];
};

export type EditRuntimeConfig = BackofficeRuntimeConfig | WebRuntimeConfig;

export const isWebRuntimeConfig = (
  config: EditRuntimeConfig
): config is WebRuntimeConfig => {
  return config.application === Application.CollaBoardWeb;
};

export type { BackofficeRuntimeConfig, WebRuntimeConfig };
